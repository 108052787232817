@layer components {
  .select {
    @apply relative;
  }

  .select select {
    @apply block py-2 pl-3.5 pr-7 w-full max-w-full;
    @apply text-md text-dark-grey;
    @apply border border-grey-600 rounded;
    @apply appearance-none cursor-pointer bg-white outline-none;
    @apply hover:border-dark-grey-400;
    @apply disabled:border-dark-grey-100 disabled:bg-grey disabled:cursor-not-allowed;
  }

  .select.error select {
    @apply border-red hover:border-red focus:border-red;
  }

  .select::after {
    font-family: 'GoSky';
    content: '\e907';
    @apply absolute right-3 top-1/2 transform -translate-y-1/2;
    @apply text-dark-grey-700 pointer-events-none;
  }
}
