@layer components {
  .input {
    @apply py-2 px-3.5 border rounded border-dark-grey-100 outline-none;
    @apply bg-white text-dark-grey;
    @apply hover:border-dark-grey-400;
    @apply focus:border-blue;
    @apply disabled:border-dark-grey-100 disabled:bg-grey disabled:cursor-not-allowed;
  }

  .input.color {
    @apply h-10 leading-5.5;
  }

  .input.error {
    @apply border-red hover:border-red focus:border-red;
  }

  .input.has-icon ~ .gsif {
    @apply text-dark-grey-100 pointer-events-none;
  }

  .input.has-icon:hover ~ .gsif,
  .input.has-icon:focus ~ .gsif {
    @apply text-dark-grey;
    @apply text-dark-grey;
  }
}
