@layer components {
  .dropdown {
    @apply relative;
  }

  .dropdown .btn-dropdown {
  }

  .dropdown .list-dropdown {
    @apply flex-col hidden;
    @apply absolute top-full right-0;
    @apply border rounded border-grey-400;
    @apply bg-white z-10;
  }

  .dropdown .btn-dropdown:focus + .list-dropdown {
    @apply flex;
  }

  .dropdown .btn-dropdown-item {
    @apply w-full px-5 py-3 whitespace-nowrap;
    @apply text-md text-dark-grey;
    @apply border-none hover:bg-grey-200;
  }
}
