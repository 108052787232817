@layer components {
  .btn {
    @apply inline-flex items-center justify-center;
    @apply py-2 px-6 rounded transition-opacity duration-200;
    @apply hover:opacity-80;
    @apply disabled:opacity-50 disabled:cursor-not-allowed;
  }

  .btn > .gsif {
    @apply mr-1;
  }

  .btn-grey {
    @apply text-dark-grey-700 bg-grey;
  }

  .btn-blue {
    @apply text-white bg-blue;
  }

  .btn-green {
    @apply text-white bg-green;
  }

  .btn-red {
    @apply text-white bg-red;
  }

  .btn-dark {
    @apply text-white bg-dark-grey;
  }

  .btn-icon {
    @apply inline-flex items-center p-1;
    @apply text-dark-grey-400 bg-white;
    @apply border-none rounded;
    @apply transform transition-colors duration-200;
  }

  .btn-icon .gsif {
    @apply text-20 leading-5;
  }

  .btn-icon.circle {
    @apply rounded-full;
  }

  .btn-icon:not([disabled]) {
    @apply hover:text-blue hover:bg-grey-400;
  }

  .btn-icon:disabled {
    @apply opacity-50 cursor-not-allowed;
  }
}
