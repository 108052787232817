.sidebar {
  flex-basis: 16.25rem;
  min-width: 16.25rem;
  @apply max-h-full p-7 bg-white shadow-light;
}

.logo-sidebar {
  height: 3.1875rem;
}

.select-sidebar-projects {
  @apply mt-8 mb-9;
}

.sidebar-item + .sidebar-item {
  @apply mt-6;
}

.sidebar-item > .sidebar-item {
  @apply pl-7 mt-3;
}

.sidebar-item-link {
  @apply text-dark-grey-400;
  @apply hover:text-opacity-80;
}

.sidebar-item-link .gsif {
  @apply text-grey-700 mr-2.5;
}

.sidebar-item-link.active,
.sidebar-item-link.parent-node {
  @apply cursor-default text-opacity-100;
}

.sidebar-item-link.active,
.sidebar-item-link.active .gsif {
  @apply text-blue;
}
