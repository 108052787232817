@layer base {
  body {
    --iphone8-origin-width: 26.4375rem;
    --iphone8-origin-height: 54.8125rem;
  }

  button,
  button:focus {
    @apply outline-none !important;
  }
}
