@layer components {
  .textarea {
    @apply py-2 px-3.5 border rounded border-dark-grey-100 outline-none;
    @apply bg-white text-dark-grey;
    @apply hover:border-dark-grey-400;
    @apply focus:border-blue;
    @apply disabled:border-dark-grey-100 disabled:bg-grey disabled:cursor-not-allowed;
  }

  .textarea.error {
    @apply border-red hover:border-red focus:border-red;
  }
}
