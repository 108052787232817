@layer components {
  .scrollbar {
    /* overflow: auto for Firefox */
    overflow: auto;
    overflow: overlay;
    /* For Firefox */
    scrollbar-color: rgba(0, 0, 0, 0.3) transparent;
  }

  /* Scrollbar for webkit */
  .scrollbar::-webkit-scrollbar {
    @apply w-2 h-2;
  }

  .scrollbar::-webkit-scrollbar-track {
    @apply bg-black bg-opacity-0;
  }

  .scrollbar::-webkit-scrollbar-thumb {
    @apply bg-black bg-opacity-30 bg-clip-padding rounded border-2 border-solid border-black border-opacity-0;
  }

  .scrollbar::-webkit-scrollbar-thumb:hover {
    @apply bg-black bg-opacity-70;
  }
}
