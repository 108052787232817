.navbar {
  @apply flex items-center bg-white shadow-light;
  height: 4.375rem;
}

.btn-navbar-user {
  @apply flex items-center p-2 pr-3 outline-none;
  @apply bg-cool-blue bg-opacity-0 hover:bg-opacity-10;
  @apply transition-colors duration-200;
  @apply border-none rounded-3xl;
}
