@import '../addon/main';

.text-h2-blue {
  font-size: px2rem(24);
  font-weight: 600;
  line-height: px2rem(29);
  color: $color-blue;
}
.text-h2-black {
  font-size: px2rem(24);
  font-weight: 500;
  line-height: px2rem(29);
  color: $limed-spruce;
}
.text-h3 {
  font-size: px2rem(18);
  font-weight: 600;
  line-height: px2rem(21);
  color: $battleship-grey;
}
.text-h4-title {
  font-size: px2rem(15);
  font-weight: 500;
  line-height: px2rem(22);
  color: $battleship-grey;
}
.text-h4 {
  font-size: px2rem(15);
  line-height: px2rem(22);
  color: $battleship-grey;
}
.text-h4-blue {
  font-size: px2rem(15);
  font-weight: 500;
  line-height: px2rem(21);
  color: $color-blue;
}
.text-h4-placeholder {
  font-size: px2rem(15);
  line-height: px2rem(22);
  color: rgba($battleship-grey, 0.5);
}
.text-h5-grey {
  font-size: px2rem(13);
  line-height: px2rem(18);
  color: $battleship-grey;
}
.text-h5-red {
  font-size: px2rem(13);
  line-height: px2rem(22);
  color: $color-pink;
}
.text-h5-white {
  font-size: px2rem(13);
  line-height: px2rem(15);
  color: $color-white;
}

.text-table-bold {
  font-size: px2rem(15);
  font-weight: 600;
  line-height: px2rem(22);
  color: $battleship-grey;
}
.text-table-body {
  font-size: px2rem(15);
  line-height: px2rem(18);
  color: $battleship-grey;
}
.text-table-link {
  font-size: px2rem(15);
  font-weight: 500;
  line-height: px2rem(18);
  color: $color-blue;
}

.text-Breadcrumb {
  font-size: px2rem(15);
  line-height: px2rem(18);
  color: $hit-gray;
}
.text-breadcrumb.active {
  font-size: px2rem(15);
  line-height: px2rem(18);
  color: $color-blue;
}

.text-button-black {
  font-size: px2rem(15);
  line-height: px2rem(18);
  color: $limed-spruce;
}
.text-button-white {
  font-size: px2rem(15);
  line-height: px2rem(18);
  color: $color-white;
}

.text-side-menu {
  font-size: px2rem(15);
  line-height: px2rem(18);
  text-decoration: none;
  color: $regent-gray;
}
.text-side-menu.active {
  font-size: px2rem(15);
  font-weight: 500;
  line-height: px2rem(18);
  color: $color-blue;
}
