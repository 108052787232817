@layer components {
  .is-grouped {
    @apply flex;
  }

  .is-grouped .input-group-text {
    @apply py-2 px-3 inline-flex items-center;
    @apply text-dark-grey bg-grey-400 border border-dark-grey-100;
  }

  .is-grouped.has-text-field > :not(:first-child) {
    @apply -ml-px;
  }

  .is-grouped > * {
    @apply rounded-none;
  }

  .is-grouped > :first-child {
    @apply rounded-l;
  }

  .is-grouped > :last-child {
    @apply rounded-r;
  }
}
