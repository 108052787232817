html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  font-size: 16px;
  font-family: 'PingFang TC', 'Microsoft JhengHei', 'Microsoft YaHei',
    'sans-serif';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
small,
em,
strong,
ul,
li,
figure {
  margin: 0;
  padding: 0;
}

button {
  outline: none;
  cursor: pointer;
}

hr {
  border-top: solid 1px #ccc;
}

// Custom style
.text-icon-align-center {
  display: flex;
  align-items: center;
}

// react-tooltip custom style
.tooltip-default {
  max-width: px2rem(192) !important;
  padding: $spacing-xs $spacing-s !important;
  font-size: px2rem(14) !important;
  line-height: px2rem(22) !important;
  word-break: break-all;
}

.escape-prefix::after {
  content: '{{';
}

.escape-suffix::after {
  content: '}}';
}
