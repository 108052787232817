@layer components {
  .checkbox {
    @apply appearance-none relative w-5 h-5 cursor-pointer bg-white;
    @apply border rounded border-dark-grey-100 outline-none;
    @apply hover:border-dark-grey-400;
    @apply checked:bg-blue checked:border-blue;
    @apply disabled:border-dark-grey-100 disabled:bg-grey disabled:cursor-not-allowed;
  }

  .checkbox:checked::after {
    font-family: 'GoSky';
    content: '\e909';
    @apply absolute left-1/2 top-1/2 transform -translate-y-1/2 -translate-x-1/2;
    @apply text-white text-12 font-semibold pointer-events-none;
  }
}
