@import-normalize;

@tailwind base;
@tailwind components;
@tailwind utilities;
@import './assets/styles/tailwind/index.css';

body {
  margin: 0;
  font-family: PingFang, Arial, 'Microsoft JhengHei', '微軟正黑體', Helvetica,
    sans-serif;
}
