@layer components {
  .table {
    @apply w-full text-15 leading-normal text-left text-dark-grey border-collapse;
  }

  .table tr {
    @apply border-b border-grey;
  }

  .table thead,
  .table tfoot {
    @apply bg-grey-300;
  }

  .table thead th {
    @apply font-semibold p-4;
  }

  .table tbody tr {
    @apply hover:bg-grey-200;
  }

  .table tbody td {
    @apply py-2 px-4 text-15 leading-4.5;
    min-height: 3.75rem;
    height: 3.75rem;
  }

  .table tfoot td {
    @apply py-2.5 px-4;
  }

  .table tbody td.col-avatar {
    @apply w-15;
  }

  .table .avatar {
    width: 1.875rem;
    height: 1.875rem;
    @apply block rounded-full;
  }

  .table .col-action {
    width: 1px; /* Compression to minimum size */
    @apply px-4 text-center;
  }

  .table .btn-group {
    @apply flex flex-nowrap items-center justify-center;
  }

  .table .link {
    @apply text-cool-blue text-15 leading-4.5 font-medium underline cursor-pointer;
  }

  .table .link.is-zero {
    @apply no-underline cursor-default;
  }
}
