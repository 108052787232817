// Primary Color
$color-blue: #0063e6;
$cool-blue: #31a6d6;

// Secondary Color
$color-pink: #ea5e6c;
$color-green: #31c873;
$color-yellow: #ffc43b;
$color-background: #f9fafd;
$color-shadow: rgba(#78a3ea, 0.1);
$limed-spruce: #313a46;
$regent-gray: #8392a2;
$battleship-grey: #6d757d;
$color-dark-grey: #6c757d;
$color-grey: #e2e6eb;
$color-white: #fff;

// === Custom color below ===

// gray
$alabaster: #fcfcfc;
$catskill-white: #f4f7fa;
$link-water: #f1f3fb;
$seashell: #f1f1f1;
$catskill-white2: #e4eaf2;
$mercury: #e4e4e4;
$athens-gray: #dfe2e7;
$alto: #d8d8d8;
$athens-gray2: #e9ecef;
$silver: #c6c6c6;
$silver-chalice: #b0b0b0;
$hit-gray: #adb5bd;
$gray-chateau: #989ea4;
$hit-gray2: #98a7ad;
$dusty-gray: #989898;
$oslo-gray: #949699;
$dove-gray: #707070;
$pale-sky: #6c757e;
$emperor: #555;
$outer-space: #373a3c;
$mine-shaft: #272727;
$cod-gray: #1e1e1e;
$shark: #1d2129;

// Red
$punch: #db4437;
$guardsman-red: #c30000;

// Yellow
$selective-yellow: #f4b400;

// Green
$frosted-mint: #deffee;
$salem: #0f9d58;
$jade: #00b354;

// Blue
$cornflower-blue: #4285f4;
$dodger-blue-2: #298ef0;
$dodger-blue: #1578ff;
$azure-radiance: #037afb;
